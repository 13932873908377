// eslint-disable-next-line
import Swiper from 'swiper/bundle';

class Slider {
    constructor() {
        // eslint-disable-next-line
        new Swiper('.swiper', {
            slidesPerView: 1,
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            },
        });
    }
}

const beitragsrechner = {
    init() {
        // Das Objekt sollte nur auf this gebunden werden, wenn es exponiert werden soll
        this.beitragsrechner = new Slider();
    },
};

// Export der API
export default beitragsrechner;
